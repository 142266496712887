@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mainVisual {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  border-top: 16px solid #FFFFFF;
  border-bottom: 16px solid #FFFFFF;
  border-left: 14px solid #FFFFFF;
  border-right: 14px solid #FFFFFF; }
  @media screen and (max-width: 568px) {
    #top .p-mainVisual {
      border: 8px solid #FFFFFF; } }
  #top .p-mainVisual__bg {
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 10px; }
  #top .p-mainVisual__catch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    color: #FFFFFF; }
    #top .p-mainVisual__catch-heading {
      font-size: 42px;
      font-weight: 400;
      margin-bottom: 18px;
      text-align: center;
      display: flex;
      flex-flow: column;
      align-items: center;
      white-space: nowrap; }
      @media screen and (max-width: 1024px) {
        #top .p-mainVisual__catch-heading {
          font-size: 36px; } }
      @media screen and (max-width: 568px) {
        #top .p-mainVisual__catch-heading {
          margin-bottom: 80px; } }
    #top .p-mainVisual__catch-img {
      width: 166px;
      height: auto; }
  #top .p-mainVisual__scroll {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    #top .p-mainVisual__scroll-txt {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.06em;
      display: block;
      color: #FFFFFF; }
    #top .p-mainVisual__scroll-bar {
      display: block;
      width: 65px;
      height: 170px;
      border-left: solid 3px #FFFFFF;
      border-right: solid 3px #FFFFFF;
      position: relative;
      overflow: hidden; }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__scroll-bar {
          height: 110px; } }
      #top .p-mainVisual__scroll-bar:before {
        content: '';
        display: block;
        width: 270px;
        height: 3px;
        background-image: linear-gradient(to right, #FFFFFF, #FFFFFF 28px, transparent 28px, transparent 38px);
        position: absolute;
        left: -177%;
        bottom: 84px;
        transform: translateX(-50%);
        transform: rotate(90deg);
        background-size: 38px 3px;
        background-repeat: repeat-x;
        animation: pathmove 2s ease-in-out infinite; }
        @media screen and (max-width: 768px) {
          #top .p-mainVisual__scroll-bar:before {
            width: 110px;
            left: -45%;
            bottom: 50px; } }

@keyframes pathmove {
  0% {
    top: 50%; }
  100% {
    top: 70%; } }

#top #wrapper {
  padding: 60px 0 0; }
  @media screen and (max-width: 768px) {
    #top #wrapper {
      padding: 40px 0 0; } }

#top #newslist {
  padding-bottom: 0; }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist__wrap {
      background-color: #F7F7F7;
      padding: 15px 0 30px; } }
  @media screen and (max-width: 768px) {
    #top #newslist .c-newslist dl dt {
      margin-bottom: 5px; } }
  #top #newslist .c-newslist dl dd {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#top .p-about {
  padding-bottom: 200px; }

#top .p-service {
  padding-top: 30px;
  position: relative;
  z-index: 100; }
  #top .p-service .l-section {
    padding-bottom: 90px; }
    @media screen and (max-width: 768px) {
      #top .p-service .l-section {
        padding-bottom: 60px; } }
  #top .p-service h2 {
    position: absolute;
    top: -138px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100; }
  #top .p-service__card {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2.5rem 2rem -2rem rgba(0, 0, 0, 0.2); }
    #top .p-service__card:hover {
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
      transition: 0.3s; }
    #top .p-service__card a:hover .p-learn-more {
      font-size: 14px;
      transition: 0.3s; }
    #top .p-service__card:before {
      content: '';
      background-color: #242424;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .4; }
    #top .p-service__card:after {
      content: '';
      display: block;
      width: calc(100% - (10px * 2));
      height: calc(100% - (15px * 2));
      border: 3px solid #FFFFFF;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1; }
    #top .p-service__card-bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 1s cubic-bezier(0, -0.075, 0.27, 0.94); }
    #top .p-service__card-inner {
      max-width: 358px;
      height: 342px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: #FFFFFF;
      padding: 152px 0 50px; }
    #top .p-service__card .p-learn-more {
      position: relative;
      font-size: 11px;
      font-weight: 600; }
      #top .p-service__card .p-learn-more:after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 3px;
        height: 35px;
        background-color: #FFFFFF;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%); }

#top .p-case {
  background-image: url(/inc/image/common/bg_marble.jpg);
  background-size: cover; }
  #top .p-case img {
    border-radius: 5px; }
  #top .p-case .l-section {
    padding: 90px 0; }
    @media screen and (max-width: 1168px) {
      #top .p-case .l-section {
        padding: 90px 30px; } }
    @media screen and (max-width: 568px) {
      #top .p-case .l-section {
        padding: 60px 15px; } }
  #top .p-case .l-col2__detail {
    color: #FFFFFF; }
  #top .p-case .c-ttl-2 > small {
    color: #FFFFFF; }
  #top .p-case .c-ttl-2 > span:before, #top .p-case .c-ttl-2 > span:after {
    background-image: linear-gradient(to right, #FFFFFF, #FFFFFF 30px, transparent 30px, transparent 39px); }

#top .u-bg--gr {
  padding-top: 30px;
  margin-top: 0; }
